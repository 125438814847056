import React, { useEffect, useState, useMemo, useCallback } from 'react';
import styles from './Variants.module.css';

const Variants = ({ variants, selectedVarient, varientHandler }) => {
  const colorOptions = useMemo(() => variants?.find(option => (option.option_type === 'color' || option.optionType === 'color'))?.choices || [], [variants]);
  const sizeOptions = useMemo(() => variants?.find(option => (option.option_type === 'size' || option.optionType === 'size'))?.choices || [], [variants]);

  const [selectedColor, setSelectedColor] = useState('');
  const [selectedSize, setSelectedSize] = useState('');

  const changeHandler = useCallback((key, value) => {
    varientHandler(prevState => ({
      ...prevState,
      [key]: value
    }));
  }, [varientHandler]);

  const handleColorSelect = useCallback((color) => {
    setSelectedColor(color.description);
    changeHandler('color', color.value);
  }, [changeHandler]);

  const handleSizeSelect = useCallback((size) => {
    setSelectedSize(size.description);
    changeHandler('size', size.value);
  }, [changeHandler]);

  useEffect(() => {
    if (colorOptions.length && colorOptions[0].description) {
      setSelectedColor(colorOptions[0].description);
      changeHandler('color', colorOptions[0].value);
    }
    if (sizeOptions.length && sizeOptions[0].description) {
      setSelectedSize(sizeOptions[0].description);
      changeHandler('size', sizeOptions[0].value);
    }
  }, [colorOptions, sizeOptions, changeHandler]);

  return (
    <div className={styles.productOptions}>
      {colorOptions.length > 0 && (
        <div className={styles.optionGroupColor}>
          <span className={styles.label}>Color: <span className={styles.colorName}>{selectedColor}</span></span>
          <div className={styles.colors}>
            {colorOptions?.filter(size => (size.visible))?.map((color) => (
              <div
                key={color.description}
                className={`${styles.colorBorderOption} ${selectedVarient?.color === color.value ? styles.selected : ''}`}
                style={['#fff', '#ffffff', 'white'].includes(color.value) ? {
                  borderColor: '#E4E4E4',
                  width: '35px',
                  height: '35px'
                } : {}}
              >
                <div
                  className={styles.colorOption}
                  disabled={!color.inStock}
                  style={{ backgroundColor: color.value.startsWith('#') ? color.value : `#${color.value}` }}
                  onClick={() => handleColorSelect(color)}
                >
                  <div
                    className={styles.colorOption}
                    style={{ backgroundColor: color.value }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {sizeOptions.length > 0 && (
        <div className={styles.optionGroup}>
          <span className={styles.label}>Size: <span className={styles.colorName}>{selectedSize}</span></span>
          <div className={styles.sizes}>
            {sizeOptions?.filter(size => (size.visible))?.map((size) => (
              <button
                type="button"
                key={size.value}
                className={`${styles.sizeOption} ${selectedVarient?.size === size.value ? styles.selected : ''}`}
                onClick={() => handleSizeSelect(size)}
                disabled={!size.inStock}
              >
                {size.description}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Variants;
