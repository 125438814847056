import React, { useState } from 'react';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import {
  array,
  arrayOf,
  bool,
  func,
  node,
  number,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import loadable from '@loadable/component';
import classNames from 'classnames';
import omit from 'lodash/omit';

import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import {
  displayDeliveryPickup,
  displayDeliveryShipping,
  displayPrice,
} from '../../util/configHelpers';
import {
  propTypes,
  LISTING_STATE_CLOSED,
  LINE_ITEM_NIGHT,
  LINE_ITEM_DAY,
  LINE_ITEM_ITEM,
  LINE_ITEM_HOUR,
  STOCK_MULTIPLE_ITEMS,
  STOCK_INFINITE_MULTIPLE_ITEMS,
} from '../../util/types';
import { formatMoney } from '../../util/currency';
import { createSlug, parse, stringify } from '../../util/urlHelpers';
import { userDisplayNameAsString } from '../../util/data';
import { isStoreOwner } from '../../util/userHelpers';
import {
  INQUIRY_PROCESS_NAME,
  getSupportedProcessesInfo,
  isBookingProcess,
  isPurchaseProcess,
  resolveLatestProcessName,
} from '../../transactions/transaction';

import CartModal from './CartModal';

import { AddToCartButton, ModalInMobile, PrimaryButton, AvatarSmall, H1, H2, ListingCard, NamedLink } from '../../components';

import css from './OrderPanel.module.css';

import LocagoButton from '../LocagoButton/LocagoButton';
import Variants from './Variants';
import RecommendationItem from "./RecommendationItem.js";
import { getRecommendedListings } from '../../containers/ListingPage/ListingPage.duck.js';
import { capitalizeFirstLetter } from '../../util/captilizeFirstLetter.js';
import { useRouteConfiguration } from '../../context/routeConfigurationContext.js';
import { updateProfileFavorites } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck.js';
import { handleToggleFavoriteListing } from '../../containers/ListingPage/ListingPage.shared.js';
// import { CartList } from '../../containers/CartPage/CartList';




const BookingTimeForm = loadable(() =>
  import(/* webpackChunkName: "BookingTimeForm" */ './BookingTimeForm/BookingTimeForm')
);
const BookingDatesForm = loadable(() =>
  import(/* webpackChunkName: "BookingDatesForm" */ './BookingDatesForm/BookingDatesForm')
);
const InquiryWithoutPaymentForm = loadable(() =>
  import(
    /* webpackChunkName: "InquiryWithoutPaymentForm" */ './InquiryWithoutPaymentForm/InquiryWithoutPaymentForm'
  )
);
const ProductOrderForm = loadable(() =>
  import(/* webpackChunkName: "ProductOrderForm" */ './ProductOrderForm/ProductOrderForm')
);

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;
const TODAY = new Date();

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const openOrderModal = (isOwnListing, isClosed, history, location) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), orderOpen: true })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const closeOrderModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'orderOpen');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const handleSubmit = (
  isOwnListing,
  isClosed,
  isInquiryWithoutPayment,
  onSubmit,
  history,
  location
) => {
  // TODO: currently, inquiry-process does not have any form to ask more order data.
  // We can submit without opening any inquiry/order modal.
  return isInquiryWithoutPayment
    ? () => onSubmit({})
    : () => openOrderModal(isOwnListing, isClosed, history, location);
};

const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };

const PriceMaybe = props => {
  const {
    price,
    publicData,
    validListingTypes,
    intl,
    marketplaceCurrency,
    showCurrencyMismatch = false,
  } = props;
  const { listingType, unitType } = publicData || {};

  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice || !price) {
    return null;
  }

  // Get formatted price or currency code if the currency does not match with marketplace currency
  const { formattedPrice, priceTitle } = priceData(price, marketplaceCurrency, intl);
  // TODO: In CTA, we don't have space to show proper error message for a mismatch of marketplace currency
  //       Instead, we show the currency code in place of the price
  return showCurrencyMismatch ? (
    <div className={css.priceContainerInCTA}>
      <div className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </div>
      <div className={css.perUnitInCTA}>
        <FormattedMessage id="OrderPanel.perUnit" values={{ unitType }} />
      </div>
    </div>
  ) : (
    <div className={css.priceContainer}>
      <p className={css.price}>{formatMoney(intl, price)}</p>
      <div className={css.perUnit}>
        <FormattedMessage id="OrderPanel.perUnit" values={{ unitType }} />
      </div>
    </div>
  );
};

const OrderPanel = props => {
  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    validListingTypes,
    lineItemUnitType: lineItemUnitTypeMaybe,
    isOwnListing,
    onSubmit,
    title,
    titleDesktop,
    author,
    authorLink,
    onManageDisableScrolling,
    onFetchTimeSlots,
    monthlyTimeSlots,
    history,
    location,
    intl,
    onFetchTransactionLineItems,
    onContactUser,
    lineItems,
    marketplaceCurrency,
    dayCountAvailableForBooking,
    marketplaceName,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    payoutDetailsWarning,
    onUpdateFavoriteListing,
    cartProps,
    orderDetail,
    transactionOrderDetail
  } = props;

  const routeConfiguration = useRouteConfiguration();
  const [selectedVarient, setSelectedVarient] = useState({
    size: '',
    color: ''
  });

  const publicData = listing?.attributes?.publicData || {};
  const { listingType, unitType, transactionProcessAlias = '', productOptions = {} } = publicData || {};
  const processName = resolveLatestProcessName(transactionProcessAlias.split('/')[0]);
  const lineItemUnitType = lineItemUnitTypeMaybe || `line-item/${unitType}`;

  const price = listing?.attributes?.price;
  const isPaymentProcess = processName !== INQUIRY_PROCESS_NAME;
  const { color = "", size = "" } = publicData || {};

  const showPriceMissing = isPaymentProcess && !price;
  const PriceMissing = () => {
    return (
      <p className={css.error}>
        <FormattedMessage id="OrderPanel.listingPriceMissing" />
      </p>
    );
  };
  const showInvalidCurrency = isPaymentProcess && price?.currency !== marketplaceCurrency;
  const InvalidCurrency = () => {
    return (
      <p className={css.error}>
        <FormattedMessage id="OrderPanel.listingCurrencyInvalid" />
      </p>
    );
  };

  const timeZone = listing?.attributes?.availabilityPlan?.timezone;
  const isClosed = listing?.attributes?.state === LISTING_STATE_CLOSED;

  const isBooking = isBookingProcess(processName);
  const shouldHaveBookingTime = isBooking && [LINE_ITEM_HOUR].includes(lineItemUnitType);
  const showBookingTimeForm = shouldHaveBookingTime && !isClosed && timeZone;

  const shouldHaveBookingDates =
    isBooking && [LINE_ITEM_DAY, LINE_ITEM_NIGHT].includes(lineItemUnitType);
  const showBookingDatesForm = shouldHaveBookingDates && !isClosed && timeZone;

  // The listing resource has a relationship: `currentStock`,
  // which you should include when making API calls.
  const isPurchase = isPurchaseProcess(processName);
  const currentStock = listing.currentStock?.attributes?.quantity;
  const isOutOfStock = isPurchase && lineItemUnitType === LINE_ITEM_ITEM && currentStock === 0;

  // Show form only when stock is fully loaded. This avoids "Out of stock" UI by
  // default before all data has been downloaded.
  const showProductOrderForm = isPurchase && typeof currentStock === 'number';

  const showInquiryForm = processName === INQUIRY_PROCESS_NAME;

  const supportedProcessesInfo = getSupportedProcessesInfo();
  const isKnownProcess = supportedProcessesInfo.map(info => info.name).includes(processName);

  const { pickupEnabled, shippingEnabled } = listing?.attributes || {};

  const listingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const displayShipping = displayDeliveryShipping(listingTypeConfig);
  const displayPickup = displayDeliveryPickup(listingTypeConfig);
  const allowOrdersOfMultipleItems = [STOCK_MULTIPLE_ITEMS, STOCK_INFINITE_MULTIPLE_ITEMS].includes(
    listingTypeConfig?.stockType
  );

  const showClosedListingHelpText = listing.id && isClosed;
  const isOrderOpen = !!parse(location.search).orderOpen;

  const subTitleText = showClosedListingHelpText
    ? intl.formatMessage({ id: 'OrderPanel.subTitleClosedListing' })
    : null;

  const authorDisplayName = userDisplayNameAsString(author, '');

  const classes = classNames(rootClassName || css.root, className);
  const titleClasses = classNames(titleClassName || css.orderTitle);

  const [isOpen, setIsOpen] = useState(false);
  const [recommendedListings, setRecommededListings] = useState([]);

  const listingImageURL = listing && listing.images?.[0].attributes.variants["listing-card"].url;

  const handleModalCartClick = () => {
    history.push('/cart');
  };

  const currentUser = useSelector(state => state.user.currentUser);
  const userType = currentUser?.attributes?.profile.publicData.userType || "not logged in";

  const onToggleFavoriteListing = handleToggleFavoriteListing({
    // ...rest,
    currentUser,
    onUpdateFavoriteListing,
    location,
    history,
    routes: routeConfiguration
  });

  const calculateListingQuantity = (cartProps) => {
    const listingId = listing?.id?.uuid;

    const addedListings = Object.values(cartProps?.cart || {})
    const currentAddedItem = addedListings?.[0]?.[listingId];

    if (publicData?.has_variants === "true") {
      const filteredVariant = currentAddedItem?.variants?.filter(item => {
        if (selectedVarient?.color && selectedVarient?.size) {
          return selectedVarient?.color === item?.color && selectedVarient?.size === item?.size
        }
        if (selectedVarient?.color) {
          return selectedVarient?.color === item?.color;
        }
        if (selectedVarient?.size) {
          return selectedVarient?.size === item?.size;
        }
      })?.[0]
      return filteredVariant?.count;
    }
    return cartProps?.count;
  }

  let PrimaryButtonComponent;

  if (isStoreOwner(userType)) {
    PrimaryButtonComponent = () => {
      return null;
    }
  }
  else if (isClosed) {
    PrimaryButtonComponent = () => {
      return (
        <div className={css.closedListingButton}>
          <FormattedMessage id="OrderPanel.closedListingButtonText" />
        </div>
      )
    }
  }
  else {
    let FormattedPanelMessage;
    if (isBooking) {
      FormattedPanelMessage = <FormattedMessage id="OrderPanel.ctaButtonMessageBooking" />
    } else if (isOutOfStock) {
      FormattedPanelMessage = <FormattedMessage id="OrderPanel.ctaButtonMessageNoStock" />
    } else if (isPurchase) {
      FormattedPanelMessage = <FormattedMessage id="OrderPanel.ctaButtonMessagePurchase" />
    } else {
      FormattedPanelMessage = <FormattedMessage id="OrderPanel.ctaButtonMessageInquiry" />
    }

    PrimaryButtonComponent = () => {
      return (
        <PrimaryButton
          onClick={handleSubmit(
            isOwnListing,
            isClosed,
            showInquiryForm,
            onSubmit,
            history,
            location
          )}
          disabled={isOutOfStock}
        >
          {FormattedPanelMessage}
        </PrimaryButton>
      )
    }
  }

  return (
    <div className={classes}>
      <ModalInMobile
        containerClassName={css.modalContainer}
        id="OrderFormInModal"
        isModalOpenOnMobile={isOrderOpen}
        onClose={() => closeOrderModal(history, location)}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
      >
        <div className={css.modalHeading}>
          <H1 className={css.heading}>{title}</H1>
        </div>

        <div className={css.orderHeading}>
          <a href={`/u/${listing?.author?.id?.uuid}`}>
            {listing?.author?.attributes?.profile?.displayName}
          </a>
          {titleDesktop ? titleDesktop : <H2 className={titleClasses}>{title}</H2>}
          {subTitleText ? <div className={css.orderHelp}>{subTitleText}</div> : null}
        </div>

        <PriceMaybe
          price={price}
          publicData={publicData}
          validListingTypes={validListingTypes}
          intl={intl}
          marketplaceCurrency={marketplaceCurrency}
        />

        {
          (publicData?.has_variants === "true" || publicData?.has_variants)
            ? <Variants
              selectedVarient={selectedVarient}
              varientHandler={setSelectedVarient}
              variants={publicData?.productOptions}
            />
            : null
        }

        {/* show add to cart button section if user is customer */}
        {!isStoreOwner(userType) ? (
          <div>

            {/* add to cart button */}
            <div>
              <AddToCartButton
                showProductOrderForm={showProductOrderForm}
                productVariants={{ ...selectedVarient, has_variants: publicData?.has_variants || "false" }}
                isBooking={isBooking}
                buttonLabel={<FormattedMessage id="AddToCartButton.label" />}
                likeButtonLabel={<FormattedMessage id="AddToLikeCartButton.label" />}
                cartLabel={<FormattedMessage id="AddToCartButton.cartLabel" />}
                {...cartProps}
                openModalOnClick={async () => {
                  setIsOpen(true);
                  const listings = await props?.recommendedListings({
                    authorId: author?.id?.uuid,
                    perPage: 4,
                    page: 1
                  })

                  const filteredListings = listings?.filter(l => l?.id?.uuid !== listing?.id?.uuid) || []
                  setRecommededListings(filteredListings);
                }}
                handleAddToFavorites={onToggleFavoriteListing}
                ensuredCurrentUser={currentUser}
              />
            </div>

            {/* model open when item add to cart */}
            <CartModal open={isOpen} onClose={() => setIsOpen(false)} style={{}}>
              <div className={css.modalTitle}>ITEM ADDED TO CART</div>
              <div className={css.cartItem}>
                <div className={css.cartProductImage}>
                  <img src={listingImageURL} alt="Product Image" />
                </div>

                <div style={{ display: "flex", flexDirection: "column", gap: "6px" }} className={css.cartProductInfo}>
                  <div style={{ fontWeight: "bold", textDecoration: "underline" }}>
                    <Link to={`/s?authorId=${author.id?.uuid}`}>
                      {authorDisplayName}
                    </Link>
                  </div>
                  <div style={{ fontWeight: "bold" }}>{title}</div>
                  {
                    selectedVarient?.color
                      ? <div >Color: {capitalizeFirstLetter(selectedVarient?.color)}</div>
                      : null
                  }
                  {
                    selectedVarient?.size
                      ? <div >Size: {(selectedVarient?.size).toUpperCase()}</div>
                      : null
                  }
                  {<div>Qty: {calculateListingQuantity(cartProps)}</div>}
                  <div style={{ fontWeight: "bold" }}>{price ? formatMoney(intl, price) : ""}</div>
                </div>
              </div>

              <div onClick={handleModalCartClick} className={css.cartModalbutton}>
                <LocagoButton className={css.cartViewCheckoutBtn}>View Cart & Checkout</LocagoButton>
              </div>
              {
                recommendedListings?.length > 0
                  ? <div className={css.recommendationSection}>
                    <div className={css.recommendationTitle}>Shop more from {authorDisplayName}</div>
                    <div className={css.recommededListingDesktop}>
                      {
                        recommendedListings?.length > 0
                          ? recommendedListings?.map((l) => {
                            const slug = createSlug(l?.attributes?.title);
                            const listingImageURL = l?.images?.[0]?.attributes?.variants["listing-card"]?.url || {};
                            return (
                              <NamedLink name="ListingPage" params={{ id: l?.id?.uuid, slug }}>
                                <div index={l?.id?.uuid} className={css.recommendedItemsMain}>
                                  <div className={css.recommendedItemsImage}>
                                    <img src={listingImageURL} alt="Product Image" />
                                  </div>
                                  <div className={css.recommendedItemsData}>
                                    <h4 className={css.headingTruncate}>{l?.author?.attributes?.profile?.displayName}</h4>
                                    <p>{l?.attributes?.title}</p>
                                    <div className={css.price}>
                                      {formatMoney(intl, l?.attributes?.price)}
                                    </div>
                                  </div>
                                </div>
                              </NamedLink>
                            );
                          })
                          : null
                      }
                    </div>
                    <RecommendationItem recommendedListings={recommendedListings} />
                  </div>
                  : null
              }
            </CartModal>
          </div>
        ) : transactionOrderDetail}

      </ModalInMobile>
      <div className={css.openOrderForm}>
        <PriceMaybe
          price={price}
          publicData={publicData}
          validListingTypes={validListingTypes}
          intl={intl}
          marketplaceCurrency={marketplaceCurrency}
          showCurrencyMismatch
        />

        <PrimaryButtonComponent />

      </div>
    </div>
  );
};

OrderPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  authorLink: null,
  payoutDetailsWarning: null,
  titleDesktop: null,
  subTitle: null,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

OrderPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  validListingTypes: arrayOf(
    shape({
      listingType: string.isRequired,
      transactionType: shape({
        process: string.isRequired,
        alias: string.isRequired,
        unitType: string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  isOwnListing: bool,
  author: oneOfType([propTypes.user, propTypes.currentUser]).isRequired,
  authorLink: node,
  payoutDetailsWarning: node,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  titleDesktop: node,
  subTitle: oneOfType([node, string]),
  onManageDisableScrolling: func.isRequired,

  onFetchTimeSlots: func.isRequired,
  monthlyTimeSlots: object,
  onFetchTransactionLineItems: func.isRequired,
  onContactUser: func,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
  marketplaceCurrency: string.isRequired,
  dayCountAvailableForBooking: number.isRequired,
  marketplaceName: string.isRequired,

  recommendedListings: func.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};


const mapDispatchToProps = (dispatch) => ({
  recommendedListings: (param) => dispatch(getRecommendedListings(param)),
  onUpdateFavoriteListing: (payload) => dispatch(updateProfileFavorites(payload)),
})

export default compose(
  withRouter,
  injectIntl,
  connect(null, mapDispatchToProps)
)(OrderPanel);
