import React from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom'

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { lazyLoadWithDimensions, withViewport } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureCurrentUser, ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';

import { AspectRatioWrapper, NamedLink, ResponsiveImage } from '../../components';

import css from './ListingCard.module.css';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { updateProfileFavorites } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { handleToggleFavoriteListing } from '../../containers/ListingPage/ListingPage.shared';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const PriceMaybe = props => {
  const { price, publicData, config, intl } = props;
  const { listingType } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && price) {
    return null;
  }

  const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);
  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);
  return (
    <div className={css.price}>
      <div className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </div>
      {isBookable ? (
        <div className={css.perUnit}>
          <FormattedMessage id="ListingCard.perUnit" values={{ unitType: publicData?.unitType }} />
        </div>
      ) : null}
    </div>
  );
};

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
    onUpdateFavoriteListing,
    showAddToLikes,
    currentUser,
    location
  } = props;

  const history = useHistory();
  const routeConfiguration = useRouteConfiguration();
  const ensuredCurrentUser = ensureCurrentUser(currentUser);

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const listingId = listing?.id?.uuid;
  const handleAddToFavorites = handleToggleFavoriteListing({
    currentUser,
    onUpdateFavoriteListing,
    history,
    location,
    routes: routeConfiguration,
    params: { id: listingId }
  });

  const isFavorite = ensuredCurrentUser?.attributes?.profile?.privateData?.favorites?.includes(listingId);
  const toggleFavorites = () => handleAddToFavorites(isFavorite, listingId);

  const userType = ensuredCurrentUser?.attributes?.profile?.publicData?.userType || "";
  const isUserCustomer = userType === "customer";

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const setActivePropsMaybe = setActiveListing
    ? {
      onMouseEnter: () => setActiveListing(currentListing.id),
      onMouseLeave: () => setActiveListing(null),
    }
    : null;

  return (
    <div className={classes}>
      <AspectRatioWrapper
        className={css.aspectRatioWrapper}
        width={aspectWidth}
        height={aspectHeight}
        {...setActivePropsMaybe}
      >
        <LazyImage
          rootClassName={css.rootForImage}
          alt={title}
          image={firstImage}
          variants={variants}
          sizes={renderSizes}
        />
        {
          ((!userType || isUserCustomer) && showAddToLikes) ?
            <div className={css.likeButton}>
              <button onClick={toggleFavorites}>
                <svg className={css.likeSvg} width="12" height="15" viewBox="0 0 19 17" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.50033 3.08332L9.05033 3.51666C9.10863 3.57714 9.17853 3.62526 9.25584 3.65812C9.33316 3.69098 9.41631 3.70792 9.50033 3.70792C9.58434 3.70792 9.66749 3.69098 9.74481 3.65812C9.82212 3.62526 9.89202 3.57714 9.95033 3.51666L9.50033 3.08332ZM7.35533 13.7683C6.09199 12.7725 4.71116 11.8 3.61533 10.5667C2.54199 9.35666 1.79199 7.94499 1.79199 6.11416H0.541992C0.541992 8.33582 1.46699 10.0308 2.68116 11.3967C3.87283 12.7383 5.39283 13.8133 6.58116 14.75L7.35616 13.7683H7.35533ZM1.79199 6.11416C1.79199 4.32249 2.80449 2.81915 4.18699 2.18666C5.53033 1.57249 7.33533 1.73499 9.05033 3.51666L9.95033 2.65082C7.91699 0.536655 5.55366 0.187488 3.66699 1.04999C1.82199 1.89416 0.541992 3.85416 0.541992 6.11416H1.79199ZM6.58116 14.75C7.00866 15.0867 7.46699 15.445 7.93116 15.7167C8.39533 15.9875 8.92533 16.2083 9.50033 16.2083V14.9583C9.24199 14.9583 8.93866 14.8583 8.56199 14.6375C8.18449 14.4175 7.79366 14.1142 7.35533 13.7683L6.58116 14.75ZM12.4195 14.75C13.6078 13.8125 15.1278 12.7392 16.3195 11.3967C17.5337 10.03 18.4587 8.33582 18.4587 6.11416H17.2087C17.2087 7.94499 16.4587 9.35666 15.3853 10.5667C14.2895 11.8 12.9087 12.7725 11.6453 13.7683L12.4195 14.75ZM18.4587 6.11416C18.4587 3.85416 17.1795 1.89416 15.3337 1.04999C13.447 0.187488 11.0853 0.536655 9.05033 2.64999L9.95033 3.51666C11.6653 1.73582 13.4703 1.57249 14.8137 2.18666C16.1962 2.81915 17.2087 4.32166 17.2087 6.11416H18.4587ZM11.6453 13.7683C11.207 14.1142 10.8162 14.4175 10.4387 14.6375C10.062 14.8575 9.75866 14.9583 9.50033 14.9583V16.2083C10.0753 16.2083 10.6053 15.9875 11.0695 15.7167C11.5345 15.445 11.992 15.0867 12.4195 14.75L11.6453 13.7683Z" fill="black" />
                </svg>
                {<FormattedMessage id={isFavorite ? "AddedToLikesButton.label" : "AddToLikesButton.label"} />}
              </button>
            </div> : null
        }

      </AspectRatioWrapper>
      <NamedLink className={css.info} name="ListingPage" params={{ id, slug }}>
        <div className={css.mainInfo}>
          {showAuthorInfo ? (
            <div className={css.authorInfo}>
              <FormattedMessage id="ListingCard.author" values={{ authorName }} />
            </div>
          ) : null}
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
        </div>
        <PriceMaybe price={price} publicData={publicData} config={config} intl={intl} />
      </NamedLink>
    </div>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
  showAddToLikes: false,
  currentUser: null
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
  showAddToLikes: bool,
  currentUser: propTypes.currentUser,
};


const mapStateToProps = state => {
  const location = state.routing.currentLocation
  const { currentUser } = state.user;

  return {
    currentUser,
    location
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateFavoriteListing: (payload) => dispatch(updateProfileFavorites(payload)),
});

const ListingCard = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withViewport,
  injectIntl
)(ListingCardComponent);


export default injectIntl(ListingCard);
